import React, { useState, useMemo } from "react";
import useCollapse from "react-collapsed";

import * as S from "./Accordion.styled";
import { theme } from "../../theme";

type IconProps = {
  color: "primary" | "black";
  rotated?: boolean;
}

const MinusIcon = ({ color: colorName, rotated }: IconProps) => {
  const color = useMemo(() => theme.colors[colorName], [colorName]);

  return (
    <S.Svg width="20" height="2" viewBox="0 0 20 2" fill="none" rotated={rotated}>
      <path d="M1.75 1H18.25" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </S.Svg>
  );
};

type AccordionProps = {
  heading: string;
  paragraph: string;
  firstChild?: boolean;
}

const Accordion = ({ heading, paragraph, firstChild }: AccordionProps) => {
  const [isExpanded, setExpanded] = useState(false);
  const { getToggleProps, getCollapseProps } = useCollapse({ isExpanded });

  const color = useMemo(() => isExpanded ? "primary" : "black", [isExpanded]);

  return (
      <S.Accordion firstChild={firstChild}>
        <S.AccordionHeader color={color} {...getToggleProps({
          onClick: () => setExpanded((prevIsExpanded) => !prevIsExpanded)
        })}>
          <h3>{heading}</h3>
          <S.IconsContainer>
            <MinusIcon color={color} />
            <MinusIcon color={color} rotated={!isExpanded} />
          </S.IconsContainer>
        </S.AccordionHeader>
        <div {...getCollapseProps()}>
          <p>{paragraph}</p>
        </div>
      </S.Accordion>
  );
};

export default Accordion;
