import styled from "styled-components";

type AccordionProps = {
  firstChild?: boolean;
}

export const Accordion = styled.article<AccordionProps>`
  max-width: 792px;
  margin: 0 auto;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.border};

  ${props => props.firstChild && `
    border-top: 1px solid ${props.theme.colors.border};
  `}

  p {
    margin-top: 0;
    margin-bottom: 24px;
  }
`;

type AccordionHeaderProps = {
  color: "primary" | "black";
}

export const AccordionHeader = styled.header<AccordionHeaderProps>`
  padding: 24px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;

  h3 {
    color: ${props => props.theme.colors[props.color]};
  }
`;

export const IconsContainer = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
`;


type SvgProps = {
  rotated?: boolean;
}

export const Svg = styled.svg<SvgProps>`
  transition: transform ease 0.6s, opacity ease 0.6s;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -1px;

  ${props => props.rotated !== undefined && `
    transform: rotate(${props.rotated ? '-90deg' : '0deg'});
    opacity: ${props.rotated ? '1' : '0'};
  `}
`;
