import styled from "styled-components";

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: ${props => props.theme.colors.white};
`;

type PageWrapperInnerProps = {
  width?: number;
  align?: "center" | "left";
  noSpacingHorizontal?: boolean;
}

export const PageWrapperInner = styled.div<PageWrapperInnerProps>`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align === "center" ? "center" : "flex-start"};
  max-width: ${props => props.width ? `${props.width}px` : props.theme.breakpoints.lg};
  margin: 0 auto;
  padding: 32px ${props => props.noSpacingHorizontal ? '0' : '20px'} 140px;
`;
