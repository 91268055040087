import React from "react";

import Divider from "./Divider";
import * as S from './PageWrapper.styled';

type Props = {
  width?: number;
  align?: 'center' | 'left';
  noSpacingHorizontal?: boolean;
}

const PageWrapper: React.FC<Props> = ({ children, width, align = "center", noSpacingHorizontal }) => (
  <S.PageWrapper>
    <S.PageWrapperInner width={width} align={align} noSpacingHorizontal={noSpacingHorizontal}>
      {children}
    </S.PageWrapperInner>
    <Divider color="gray5" />
  </S.PageWrapper>
);

export default PageWrapper;
