import React from "react";
import { PageProps } from "gatsby";
import { useTranslation } from "react-i18next";
import { v1 } from "uuid";

import Layout from "../../components/layout/Layout";
import PageWrapper from "../../components/layout/PageWrapper";
import PageTitle from "../../components/layout/PageTitle";
import Accordion from "../../components/ui-kits/Accordion";

const dataLength = 5; // TODO Replace with real amount
const data = [...Array(dataLength).keys()].map(i => ({
  id: v1(),
  question: `faq.q&a.${i}.question`,
  answer: `faq.q&a.${i}.answer`
}));

const FaqPage = ({ location }: PageProps) => {
  const { t } = useTranslation();

  return (
    <Layout location={location}>
      <PageWrapper>
        <PageTitle
          heading={t("faq.heading")}
          paragraph={t("faq.paragraph")}
        />
        {data.map((item, i) => (
          <Accordion
            heading={t(item.question)}
            paragraph={t(item.answer)}
            firstChild={i === 0}
            key={item.id}
          />
        ))}
      </PageWrapper>
    </Layout>
  );
};

export default FaqPage;